export const getVideoRatio = aspectRatio => {
  let ratio = 9 / 16
  switch (aspectRatio) {
    case "3:2":
      ratio = 2 / 3
      break
    case "4:3":
      ratio = 3 / 4
      break
    case "16:9":
      ratio = 9 / 16
      break
    case "4:5":
      ratio = 5 / 4
      break
    case "2:3":
      ratio = 3 / 2
      break
    case "3:4":
      ratio = 4 / 3
      break
    case "9:16":
      ratio = 16 / 9
      break
    case "1:1":
      ratio = 1 / 1
      break
    case "default":
      ratio = null
      break
    default:
      break
  }
  return ratio * 100
}
