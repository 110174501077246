import React from "react"
import styled from "styled-components"
import Image from "src/components/Components/Image"
import SbEditable from "storyblok-react"
import Box from "src/components/UI/Layout/Box"
import Hover from "src/components/UI/Elements/Hover"
import { getImageRatio, getAspectRatio } from "src/helpers/getImageRatio"
import Markdown from "src/storyblok/Components/Markdown"
import LinkComponent from "src/components/Components/LinkComponent"

const ImageWrapper = styled(Box)`
  position: relative;
  animation: fadeInBackground 0.1s ease forwards;
  background: transparent;

  &.has-ratio {
    padding-bottom: ${props => (props.ratio ? props.ratio : "0%")};

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }

  @keyframes fadeInBackground {
    0% {
      background: transparent;
    }
    100% {
      background: ${props => props.theme.colors.green};
    }
  }
`

const LinkWrapper = ({ children, hasLink, link, title }) =>
  hasLink ? (
    <LinkComponent blok={{ link: link, text: title }}>{children}</LinkComponent>
  ) : (
    children
  )

const ImageBlok = ({
  blok,
  customRatio,
  hideCaption = false,
  hover,
  spacing = true,
  hasParentLink,
  imageSize,
  width,
  isCritical = false,
}) => {
  const { image, ratio, alt, text, link } = blok
  const aspectRatio = customRatio ? customRatio : ratio ? ratio : null

  if (!imageSize && width) {
    switch (width) {
      case "3/12":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "25vw" }
        break
      case "1/3":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "33vw" }
        break
      case "1/2":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
        break
      case "2/3":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "66vw" }
        break
      case "5/6":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "90vw" }
        break
      case "1/1":
        imageSize = "100vw"
        break
      default:
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
        break
    }
  }

  const hasLink = !hasParentLink && link && link.cached_url ? true : null
  let imageRatio =
    aspectRatio && aspectRatio !== "default" ? getImageRatio(aspectRatio) : null

  return (
    <SbEditable content={blok}>
      <Box
        mb={
          !hideCaption && text && !spacing
            ? [2, null, null, 6]
            : spacing
            ? [2, null, null, 3]
            : 0
        }
        className="img"
      >
        <LinkWrapper hasLink={hasLink ? true : false} link={link} title={alt}>
          <ImageWrapper
            ratio={
              aspectRatio && aspectRatio !== "default"
                ? getAspectRatio(aspectRatio)
                : "0%"
            }
            className={["img-wrapper", imageRatio ? "has-ratio" : ""].join(" ")}
          >
            <Image
              crop
              sizes={imageSize ? imageSize : "50vw"}
              ratio={imageRatio}
              src={image}
              alt={alt}
              isCritical={isCritical}
            />
            {hover && <Hover className="hover" />}
          </ImageWrapper>
        </LinkWrapper>
        {!hideCaption && text && (
          <Box mt={2}>
            <Markdown blok={blok} />
          </Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default ImageBlok
