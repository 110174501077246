import React from "react"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import LinkComponent from "src/components/Components/LinkComponent"
import ImageBlok from "src/storyblok/Components/ImageBlok"
import VideoBlok from "src/storyblok/Components/VideoBlok"
import VimeoBlok from "src/storyblok/Components/VimeoBlok"
import { getContentWidths } from "src/helpers/getContentWidths"

const Wrapper = styled(Flex)`
  a {
    text-decoration: none;
  }
`

const LinkWrapper = ({ children, hasLink, link, title }) =>
  hasLink ? (
    <LinkComponent blok={{ link: { cached_url: link }, text: title }}>
      {children}
    </LinkComponent>
  ) : (
    children
  )

const Media = ({ thumbnail, ratio, hasLink, imageSize, spacing }) =>
  thumbnail
    ? thumbnail &&
      thumbnail.length > 0 &&
      thumbnail.map(blok => {
        const { _uid } = blok
        switch (blok.component) {
          case "image":
            return (
              <ImageBlok
                customRatio={ratio}
                key={_uid}
                blok={blok}
                imageSize={imageSize}
                hideCaption={true}
                hover={hasLink}
                hasParentLink={hasLink}
                spacing={spacing}
              />
            )
          case "video":
            return (
              <VideoBlok
                customRatio={ratio}
                key={_uid}
                blok={blok}
                hideCaption={true}
                hover={hasLink}
                hasParentLink={hasLink}
                spacing={spacing}
              />
            )
          case "vimeo":
            return (
              <VimeoBlok
                customRatio={ratio}
                key={_uid}
                blok={blok}
                hideCaption={true}
                hover={hasLink}
                hasParentLink={hasLink}
                spacing={spacing}
              />
            )
          default:
            return null
        }
      })
    : null

const Case = ({ item, width, ratio }) => {
  const { thumbnail, year, name, headline, slug } = item
  const highlightTitle = name
  const hasLink = slug ? true : null
  let imageSize = "50vw"
  const { labelWidth, titleWidth } = getContentWidths(width, false)

  switch (width) {
    case "3/12":
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "25vw" }
      break
    case "1/3":
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "33vw" }
      break
    case "1/2":
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
      break
    case "2/3":
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "66vw" }
      break
    case "5/6":
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "90vw" }
      break
    case "1/1":
      imageSize = "100vw"
      break
    default:
      imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
      break
  }

  return (
    <Wrapper
      width={1}
      flexDirection="column"
      mb={[6, null, null, 6]}
      hasLink={hasLink ? 1 : 0}
    >
      <LinkWrapper hasLink={hasLink} link={slug} title={highlightTitle}>
        <Box className="media image-wrapper" mb={[1, null, null, 2]}>
          <Media
            ratio={ratio}
            thumbnail={thumbnail}
            hasLink={hasLink}
            imageSize={imageSize}
            hasParentLink={true}
            spacing={false}
          />
        </Box>

        <Row flexDirection="row">
          {year && (
            <Column width={[1 / 6, null, null, labelWidth]}>
              <p>{year}</p>
            </Column>
          )}

          <Column width={[5 / 6, null, null, titleWidth]}>
            {name && <p>{name}</p>}
            {headline && <p>{headline}</p>}
          </Column>
        </Row>
      </LinkWrapper>
    </Wrapper>
  )
}

export default Case
