import React from "react"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import ReactMarkdown from "react-markdown/with-html"
import Box from "src/components/UI/Layout/Box"
import { Above, Below } from "src/components/UI/Breakpoints"
import H1 from "src/components/UI/Typography/H1"
import { LinkRenderer } from "src/helpers/markdownRenderers"

const CaseHeader = ({ blok, name }) => {
  const { headline, year, excerpt, tasks, title } = blok
  return (
    <SbEditable content={blok}>
      <Section mb={[7, null, null, 9]} pt={6}>
        <Container>
          <Above breakpoint="desktop">
            <Row>
              <Column width={4 / 12} mt={[0, null, null, "5px"]}>
                <Row mb={[4, null, null, 6]}>
                  <Column width={1 / 4}>{year}</Column>
                  <Column width={3 / 4}>
                    <h1>{title ? title : name}</h1>
                    <p>{headline}</p>
                  </Column>
                </Row>
                {tasks && (
                  <Row mb={2}>
                    <Column
                      width={1 / 4}
                      ml={[0, null, null, `${(1 / 4) * 100}%`]}
                    >
                      <p>{"Scope"}</p>
                    </Column>
                    <Column width={2 / 4}>
                      <ReactMarkdown
                        source={tasks}
                        renderers={{ link: LinkRenderer }}
                      />
                    </Column>
                  </Row>
                )}
              </Column>

              <Column mr={[0, null, null, `${(1 / 12) * 100}%`]} width={7 / 12}>
                <H1 as="span">
                  <ReactMarkdown
                    source={excerpt}
                    renderers={{ link: LinkRenderer }}
                  />
                </H1>
              </Column>
            </Row>
          </Above>
          <Below breakpoint="desktop">
            <Row mb={3} flexDirection="row">
              <Column width={1 / 6}>{year}</Column>
              <Column width={5 / 6}>
                <h1>{name}</h1>
                <p>{headline}</p>
              </Column>
            </Row>
            <Row mb={7}>
              <Column width={5 / 6} ml={`${(1 / 6) * 100}%`}>
                <H1 as="span">
                  <ReactMarkdown
                    source={excerpt}
                    renderers={{ link: LinkRenderer }}
                  />
                </H1>
              </Column>
            </Row>
            <Row flexDirection="row">
              {tasks && (
                <React.Fragment>
                  <Column width={2 / 6}>
                    <Box pr={3}>
                      <p>{"Scope"}</p>
                    </Box>
                  </Column>
                  <Column width={4 / 6}>
                    <ReactMarkdown
                      source={tasks}
                      renderers={{ link: LinkRenderer }}
                    />
                  </Column>
                </React.Fragment>
              )}
            </Row>
          </Below>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default CaseHeader
