import styled from "styled-components"
import Heading from "src/components/UI/Typography/Heading"
import { getScaledFontSize } from "src/helpers/getScaledFontSize"

const H1 = styled(Heading)``

H1.defaultProps = {
  as: "h1",
  lineHeight: "1.1",
  fontSize: [
    "21px",
    null,
    null,
    "36px",
    null,
    getScaledFontSize("36px", "1600px", 2),
  ],
  letterSpacing: "0px",
}

export default H1
