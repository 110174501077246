import styled from "styled-components"
import Box from "src/components/UI/Layout/Box"

const Hover = styled(Box)`
  ${props => props.theme.mixins.transition("opacity", "0.15s")}
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: block;
  }
`

export default Hover
