import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown/with-html"
import StyledLink from "src/components/UI/Elements/StyledLink"
import Box from "src/components/UI/Layout/Box"
// import Row from "src/components/UI/Layout/Grid/Column"
// import Column from "src/components/UI/Layout/Grid/Column"
import { LinkRenderer } from "src/helpers/markdownRenderers"
import { getScaledFontSize } from "src/helpers/getScaledFontSize"
const StyledMarkdown = styled(Box)`
  p {
      ${props =>
        props.textStyle === "hand" && props.theme.mixins.fontBedowTextHand}
        line-height: ${props =>
          props.textStyle === "hand" ? "1.1" : "inherit"};
        font-size: ${props =>
          props.textStyle === "hand" ? "21px" : "inherit"};
    margin-bottom: ${props => props.theme.space[2]};

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
        font-size: ${props =>
          props.textStyle === "hand" ? "36px" : "inherit"};
      margin-bottom: ${props => props.theme.space[3]};
    }

    @media (min-width: ${props => props.theme.breakpoints[5]}) {
        font-size: ${props =>
          props.textStyle === "hand"
            ? getScaledFontSize("36px", "1600px", 2)
            : "inherit"};

    }

    &:last-of-type {
      margin-bottom: 0;
    }

  }
`

const Text = ({ blok, escapeHtml = true }) => {
  const { text, mobileIndentation, style } = blok
  return (
    <SbEditable content={blok}>
      <StyledMarkdown
        textStyle={style}
        mb={[2, null, null, 3]}
        ml={[mobileIndentation ? (1 / 6) * 100 + "%" : 0, null, null, 0]}
      >
        <StyledLink>
          <ReactMarkdown
            renderers={{ link: LinkRenderer }}
            escapeHtml={escapeHtml}
            className="markdown"
            source={text}
          />
        </StyledLink>
      </StyledMarkdown>
    </SbEditable>
  )
}

export default Text
