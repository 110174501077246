import React from "react"
import Components from "src/storyblok/Components.js"
import Box from "src/components/UI/Layout/Box"
import SeoWrapper from "src/components/SeoWrapper/SeoWrapper"
import { getSeoData } from "../../helpers/getSeoData"

const PageHome = ({ blok, isStartPage, name }) => {
  const { header, body, SEO } = blok

  const seoData = getSeoData(SEO)

  if (!seoData.title) {
    seoData.title = name
  }

  const hasHero = header && header.length > 0 && header[0].component === "hero"

  return (
    <Box pt={!hasHero ? ["12px", null, null, 3] : 0}>
      <SeoWrapper {...seoData} />
      {header &&
        header.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isCritical: true,
          })
        )}

      {body &&
        body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isStartPage: isStartPage,
          })
        )}
    </Box>
  )
}

export default PageHome
