import React from "react"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import GridItem from "src/storyblok/Components/GridItem"
import { checkColorHex } from "src/helpers/checkColorHex"

const Grid = ({ blok, colMb = 0 }) => {
  const { items, horizontal_alignment, no_spacing, color_background } = blok
  const spacing = no_spacing ? false : true
  const hasFullWidth =
    items &&
    items.length > 0 &&
    items.filter(item => item.width === "1/1").length > 0 &&
    no_spacing
      ? true
      : null

  return (
    <SbEditable content={blok}>
      <Section
        px={!spacing ? 0 : ["6px", null, null, "12px"]}
        className={`grid${hasFullWidth ? " grid--full-width" : ""}`}
        bg={checkColorHex(color_background, "background")}
      >
        <Container
          overflow="hidden"
          px={!spacing ? 0 : ["6px", null, null, "12px"]}
        >
          {items && items.length > 0 && (
            <Row
              justifyContent={[
                "flex-start",
                null,
                null,
                horizontal_alignment === "right"
                  ? "flex-end"
                  : horizontal_alignment === "center"
                  ? "center"
                  : "flex-start",
              ]}
            >
              {items.map(blok => (
                <GridItem
                  key={blok._uid}
                  spacing={spacing}
                  blok={blok}
                  mb={colMb}
                />
              ))}
            </Row>
          )}
        </Container>
      </Section>
    </SbEditable>
  )
}

export default Grid
