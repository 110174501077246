import React from "react"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Box from "src/components/UI/Layout/Box"
import Highlight from "src/storyblok/Components/Highlight"
import { Above, Below } from "src/components/UI/Breakpoints"
import Carousel from "src/components/Modules/Carousel"

const items = ({ blok }) => {
  const { items } = blok

  return (
    <React.Fragment>
      <Below breakpoint="mobile">
        <Box pb={4}>
          <Carousel>
            {items &&
              items.length > 0 &&
              items.map(blok => (
                <Box
                  key={blok._uid}
                  px={"6px"}
                  width={5 / 6}
                  // mb={[3, null, null, 3]}
                >
                  <Highlight blok={blok} spacing={false} carousel={true} />
                </Box>
              ))}
          </Carousel>
        </Box>
      </Below>

      <Above breakpoint="mobile">
        <SbEditable content={blok}>
          <Section>
            {items && items.length > 0 && (
              <Container>
                <Row>
                  {items.map(blok => (
                    <Column key={blok._uid} width={[4 / 12]}>
                      <Highlight
                        imageSize={{
                          mobile: "100vw",
                          tablet: "100vw",
                          desktop: "33vw",
                        }}
                        blok={blok}
                      />
                    </Column>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        </SbEditable>
      </Above>
    </React.Fragment>
  )
}

export default items
