// Page types
import PageApproach from "./Pages/PageApproach"
import PageCase from "./Pages/PageCase"
import PageDefault from "./Pages/PageDefault"
import PageHome from "./Pages/PageHome"
import PageWork from "./Pages/PageWork"
import PageNews from "./Pages/PageNews"

// Modules
import ApproachHeader from "./Modules/ApproachHeader"
import CaseHeader from "./Modules/CaseHeader"
import Highlights from "./Modules/Highlights"
import Hero from "./Modules/Hero"
// SeoComponent

// Global Modules
import Footer from "./GlobalModules/Footer"
import GlobalSeo from "src/storyblok/GlobalModules/GlobalSeo"
import Menu from "../components/GlobalModules/Menu"

// Content Components
import Approach from "./ContentComponents/Approach"
import Chapter from "./ContentComponents/Chapter"
import Grid from "./ContentComponents/Grid"
import Insights from "./ContentComponents/Insights"
import Process from "./ContentComponents/Process"
import Spacing from "./ContentComponents/Spacing"
import Team from "./ContentComponents/Team"
import Text from "./ContentComponents/Text"

// Components
import Case from "./Components/CaseBlok"
import GridItem from "./Components/GridItem"
import GridLink from "./Components/GridLink"
import Highlight from "./Components/Highlight"
import Image from "./Components/ImageBlok"
import Insight from "./Components/Insight"
import LinkBlok from "./Components/LinkBlok"
import List from "./Components/List"
import Markdown from "./Components/Markdown"
import Member from "./Components/Member"
// import Step from "./Components/Step"
import Video from "./Components/VideoBlok"
import Vimeo from "./Components/VimeoBlok"
import VideoFull from "./Components/VideoFull"
import ComponentNotFound from "./ComponentNotFound"

const ComponentList = {
  // Page types
  page_approach: PageApproach,
  page_case: PageCase,
  page_default: PageDefault,
  page_home: PageHome,
  page_work: PageWork,
  page_news: PageNews,

  // Modules
  approach_header: ApproachHeader,
  case_header: CaseHeader,
  highlights: Highlights,
  hero: Hero,

  // Global Modules
  footer: Footer,
  global_seo: GlobalSeo,
  menu: Menu,

  // Content Components
  approach: Approach,
  grid: Grid,
  chapter: Chapter,
  insights: Insights,
  process: Process,
  spacing: Spacing,
  team: Team,
  text: Text,

  // Components
  case: Case,
  grid_item: GridItem,
  grid_link: GridLink,
  highlight: Highlight,
  image: Image,
  insight: Insight,
  link: LinkBlok,
  list: List,
  markdown: Markdown,
  member: Member,
  //   step: Step,
  video: Video,
  vimeo: Vimeo,
  video_full: VideoFull,
}

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
