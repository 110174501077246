import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"

const Image = styled("img")`
  max-width: 600px;
  height: auto;
`

const GlobalSeo = ({ blok }) => {
  if (!blok) {
    return null
  }
  return (
    <SbEditable content={blok}>
      <h1>{blok.title}</h1>
      <p>{blok.description}</p>
      {blok.share_image && <Image src={blok.share_image} />}
    </SbEditable>
  )
}

export default GlobalSeo
