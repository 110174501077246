import React from "react"
import SbEditable from "storyblok-react"
import LinkComponent from "src/components/Components/LinkComponent"
import StyledLink from "src/components/UI/Elements/StyledLink"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"

const GridLink = ({ blok, text, className, onClick, style }) => {
  const { margin_bottom_desktop } = blok

  return (
    <SbEditable content={blok}>
      <Row mt="auto" mb={[5, null, null, `${margin_bottom_desktop}px`]}>
        <Column
          width={[5 / 6, null, null, 12 / 12]}
          ml={[(1 / 6) * 100 + "%", null, null, 0]}
        >
          <StyledLink textAlign={["left", null, null, "left"]}>
            <LinkComponent
              blok={blok}
              text={text}
              className={className}
              onClick={onClick}
              style={style}
            ></LinkComponent>
          </StyledLink>
        </Column>
      </Row>
    </SbEditable>
  )
}

export default GridLink
