import React from "react"
import isNode from "is-node"

import { formatImage } from "src/helpers/formatImage"

const getSrcSet = (src, ratio) => {
  const array = []
  array.push(`${formatImage(src, 600, ratio)} 600w`)
  array.push(`${formatImage(src, 1000, ratio)} 1000w`)
  array.push(`${formatImage(src, 1300, ratio)} 1300w`)
  array.push(`${formatImage(src, 1600, ratio)} 1600w`)
  array.push(`${formatImage(src, 2000, ratio)} 2000w`)
  array.push(`${formatImage(src, 2400, ratio)} 2400w`)
  return array.join(", ")
}

export const breakpoints = {
  mobile: "(max-width: 767px)",
  tablet: "(min-width: 768px) and (max-width: 1023px)",
  desktop: "(min-width: 1024px)",
}

class Image extends React.Component {
  imageRef
  observer

  static defaultProps = {
    sizes: {
      mobile: "100vw",
      tablet: "50vw",
      desktop: "50vw",
    },
  }

  constructor(props) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 200px 0px",
      })
    }
  }

  observeHandler = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const src = this.imageRef.getAttribute("data-src") || ""
        const srcSet = this.imageRef.getAttribute("data-srcset") || ""
        this.imageRef.setAttribute("src", src)
        this.imageRef.setAttribute("srcset", srcSet)
        this.imageRef.onload = () => {
          this.imageRef.style.opacity = 1
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  get placeholder() {
    const { ratio } = this.props
    if (!ratio) {
      // Return an empty pixel
      return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg=="
    }
    const viewBox = `0 0 ${ratio} 1`
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      "#FFFFFF00"
    )}" width="100%" height="100%" /></svg>`
  }

  getSizes() {
    const { sizes } = this.props
    if (typeof sizes === "string") {
      return sizes
    } else if (typeof sizes === "object") {
      return Object.keys(sizes)
        .map(width => `${breakpoints[width]} ${sizes[width]}`)
        .join(",")
    }
    return ""
  }

  render() {
    let { src, alt, ratio, className, isCritical } = this.props
    if (src.endsWith(".svg")) {
      return <img src={src} alt={alt} className={className} />
    } else {
      if (className) {
        className = className + " o-image"
      } else {
        className = "o-image"
      }
      if (isCritical) {
        className = className + " critical"
      }
    }

    const srcSet = getSrcSet(src, ratio)
    const formatSrc = formatImage(src, 2400, ratio)
    let webpChecked = false
    let webpAccepted = false

    if (!isNode && document) {
      webpChecked = document
        .getElementsByTagName("html")[0]
        .classList.contains("webp-checked")
      webpAccepted = document
        .getElementsByTagName("html")[0]
        .classList.contains("support-webp")
    }

    if (typeof alt !== "string") {
      alt = ""
    }

    return (
      <React.Fragment>
        {isNode && (
          <React.Fragment>
            <noscript>
              <img
                className={className}
                sizes={this.getSizes()}
                srcSet={srcSet}
                src={formatSrc}
                alt={alt}
              />
            </noscript>
            <div />
            <img
              className={className}
              data-srcset={srcSet}
              sizes={this.getSizes()}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          </React.Fragment>
        )}
        {webpChecked &&
          (webpAccepted ? (
            <img
              ref={x => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              className={className}
              data-srcset={srcSet}
              sizes={this.getSizes()}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ) : (
            <img
              ref={x => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              className={className}
              data-srcset={srcSet}
              sizes={this.getSizes()}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ))}
      </React.Fragment>
    )
  }
}

export default Image
