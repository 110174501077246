import React from "react"
import SbEditable from "storyblok-react"
import Image from "src/components/Components/Image"
import ImageWrapper from "src/components/UI/Elements/ImageWrapper"
import Components from "src/storyblok/Components.js"
import Flex from "src/components/UI/Layout/Flex"
import SeoWrapper from "src/components/SeoWrapper/SeoWrapper"
import { getSeoData } from "../../helpers/getSeoData"

const PageDefault = ({ blok, name }) => {
  const { hero_image, body, SEO } = blok
  const seoData = getSeoData(SEO)

  if (!seoData.title) {
    seoData.title = name
  }
  if (!seoData.ogImage) {
    seoData.ogImage = hero_image
  }

  return (
    <Flex width={1} flexDirection="column">
      <SeoWrapper {...seoData} />
      {hero_image && (
        <SbEditable content={blok}>
          <ImageWrapper>
            <Image src={hero_image} alt={name} ratio={3 / 2} isCritical />
          </ImageWrapper>
        </SbEditable>
      )}
      {body &&
        body.length > 0 &&
        body.map((blok, index) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isFirst: index === 0 ? true : null,
          })
        )}
    </Flex>
  )
}

export default PageDefault
