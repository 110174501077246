import cases from "src/json/cases.json"

// Takes array with tag names (string) and return items that match with that
export const getCasesByTags = (tags, name) => {
  return cases
    ? cases
        .filter(item => item.name !== name)
        .filter(item => {
          return (
            item.tags && item.tags.filter(tag => tags.includes(tag)).length > 0
          )
        })
    : null
}

export const getCaseById = id => {
  return cases.find(item => item.uuid === id)
}
