export const getContentWidths = (width, hasText) => {
  let labelWidth = 1 / 4
  let titleWidth = 3 / 4
  let textWidth = false
  switch (width) {
    case "1/2":
      labelWidth = 1 / 6
      titleWidth = hasText ? 2 / 6 : 5 / 6
      textWidth = 3 / 6
      break
    case "2/3":
      labelWidth = 1 / 8
      titleWidth = hasText ? 3 / 8 : 7 / 8
      textWidth = 4 / 8
      break
    case "5/6":
      labelWidth = 1 / 10
      titleWidth = hasText ? 3 / 10 : 9 / 10
      textWidth = "480px"
      break
    case "1/1":
      labelWidth = 1 / 12
      titleWidth = hasText ? 2 / 12 : 11 / 12
      textWidth = 5 / 12
      break
    default:
      break
  }
  return { labelWidth, titleWidth, textWidth }
}
