/* eslint-disable */
import React, { Component } from "react"
import styled from "styled-components"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Text from "src/components/UI/Typography/Text"
import Markdown from "src/storyblok/Components/Markdown"

const Input = styled("input")`
  ${props => props.theme.mixins.fontBedowTextHead}
  ${props => props.theme.mixins.fontSizeBody}
  background: transparent;
  color: ${props => props.theme.colors.black};
  border: none;
  border: 1px solid ${props => props.theme.colors.black};
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  outline: none;
  padding: 0 8px;
  height: 39px;
  width: 100%;

  ::-webkit-input-placeholder {
      ${props => props.theme.mixins.fontBedowTextHead}
      ${props => props.theme.mixins.fontSizeBody}
      color: ${props => props.theme.colors.black};
  }
  :-ms-input-placeholder {
      ${props => props.theme.mixins.fontBedowTextHead}
      ${props => props.theme.mixins.fontSizeBody}
      color: ${props => props.theme.colors.black};
  }
  :-moz-input-placeholder {
      ${props => props.theme.mixins.fontBedowTextHead}
      ${props => props.theme.mixins.fontSizeBody}
      color: ${props => props.theme.colors.black};
    }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  border-radius: 0px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid ${props => props.theme.colors.black};
  background: ${props => (props.checked ? props.theme.colors.black : "")};
`

const Label = styled("label")`
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  display: block;

  a {
    color: ${props => props.theme.colors.black};
  }
`

const Legal = styled(Box)`
  p,
  a {
    color: black !important;
  }
`

const Signup = styled.button`
  ${props => props.theme.mixins.fontSizeBody}
  ${props => props.theme.mixins.fontBedowTextHead}
  ${props => props.theme.mixins.buttonDefault}
  padding: 16px 8px;
  margin-right: -10px;
  color: black;
  text-align: right;
  text-decoration: underline;
  &:disabled {
    opacity: 1;
    text-decoration: none;
  }
`

const url =
  "https://bedow.us9.list-manage.com/subscribe/post?u=55e4fa52f5b10626daa7c3f95&amp;id=625f56a187"

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} />
  </CheckboxContainer>
)

class CustomForm extends Component {
  state = {
    emailValid: false,
    checked: false,
  }

  validateEmail = e => {
    const email = e.target.value
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValid = re.test(String(email).toLowerCase())

    if (this.state.emailValid !== isValid) {
      this.setState({
        emailValid: isValid,
      })
    }
  }

  handleCheckboxChange = () => {
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { status, message, onValidated, legal } = this.props
    const { emailValid } = this.state

    let email
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })

    return (
      <Flex flexDirection="column" maxWidth="241px" mt={[1, null, null, 1]}>
        <Flex
          mt="auto"
          width={1}
          flexDirection={["column", null, "row"]}
          alignItems="flex-end"
        >
          <Input
            aria-label="Din e-postadress"
            ref={node => (email = node)}
            type="email"
            onChange={this.validateEmail}
          />
        </Flex>
        <Legal mt={[1, null, null, 1]}>
          <Label>
            <Checkbox
              type="checkbox"
              checked={this.state.checked}
              onChange={this.handleCheckboxChange}
            />
            <Text>{legal && <Markdown blok={{ text: legal }} />}</Text>
          </Label>
        </Legal>
        <Flex
          alignItems="flex-end"
          justifyContent="flex-end"
          flexDirection="column"
        >
          <Signup
            label="Registrera"
            light
            disabled={
              !emailValid ||
              status === "success" ||
              status === "sending" ||
              !this.state.checked
            }
            onClick={submit}
          >
            {status === "sending" && "Sending"}
            {status === "error" && "Try again"}
            {status === "success" && "Success"}
            {!status && "Subscribe"}
          </Signup>
          {message && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          )}
        </Flex>
      </Flex>
    )
  }
}

const Newsletter = ({ blok }) => {
  const label = blok && blok.newsletter_label ? blok.newsletter_label : null
  const legal =
    blok && blok.newsletter_checkbox ? blok.newsletter_checkbox : null

  return (
    <Box className="newsletter">
      {label ? (
        <Markdown blok={{ text: label }} />
      ) : (
        <Text is="p">Newsletter</Text>
      )}
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            legal={legal}
          />
        )}
      />
    </Box>
  )
}

export default Newsletter
