import React from "react"
import SbEditable from "storyblok-react"
import Case from "src/components/Components/Case"
import { getCaseById } from "src/helpers/getCases"
const CaseBlok = ({ blok, width }) => {
  const item = blok && blok.case ? getCaseById(blok.case) : null
  return item ? (
    <SbEditable content={blok}>
      <Case item={item} width={width} />
    </SbEditable>
  ) : null
}

export default CaseBlok
