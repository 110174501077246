import React, { useEffect, createRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SliderWrapper = styled(Slider)`
  margin-left: calc(-14% - 2px);
`

let firstClientX, clientX

const preventTouch = e => {
  const minValue = 10 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    // e.preventDefault()
    e.returnValue = false

    return false
  }
}

const touchStart = e => {
  firstClientX = e.touches[0].clientX
}

const settings = {
  touchThreshold: 10,
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  centerMode: true,
  centerPadding: "14%",
  infinite: true,
  lazyLoad: true,
}

const Carousel = ({ height, children, dotsRight, dotsWhite }) => {
  let containerRef = createRef()

  useEffect(() => {
    let current =
      containerRef && containerRef.current ? containerRef.current : null
    if (current) {
      current.addEventListener("touchstart", touchStart)
      current.addEventListener("touchmove", preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (current) {
        current.removeEventListener("touchstart", touchStart)
        current.removeEventListener("touchmove", preventTouch, {
          passive: false,
        })
      }
    }
  })

  return (
    <div ref={containerRef}>
      <SliderWrapper
        className={[
          dotsRight ? "dots-right" : "",
          dotsWhite ? "dots-white" : "",
        ]}
        height={height}
        {...settings}
      >
        {children}
      </SliderWrapper>
    </div>
  )
}

export default Carousel
