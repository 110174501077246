export const getImageRatio = aspectRatio => {
  let ratio = 2 / 3

  switch (aspectRatio) {
    case "3:2":
      ratio = 3 / 2
      break
    case "4:3":
      ratio = 4 / 3
      break
    case "16:9":
      ratio = 16 / 9
      break
    case "4:5":
      ratio = 4 / 5
      break
    case "2:3":
      ratio = 2 / 3
      break
    case "3:4":
      ratio = 3 / 4
      break
    case "9:16":
      ratio = 9 / 16
      break
    case "1:1":
      ratio = 1 / 1
      break
    case "default":
      ratio = null
      break
    default:
      break
  }
  return ratio
}

export const getAspectRatio = aspectRatio => {
  let ratio = 2 / 3
  switch (aspectRatio) {
    case "3:2": // Landscape
      ratio = 2 / 3
      break
    case "4:3": // Landscape
      ratio = 3 / 4
      break
    case "16:9": // Landscape
      ratio = 9 / 16
      break
    case "4:5": // Portrait
      ratio = 5 / 4
      break
    case "2:3": // Portrait
      ratio = 3 / 2
      break
    case "3:4": // Portrait
      ratio = 4 / 3
      break
    case "9:16": // Portrait
      ratio = 16 / 9
      break
    case "1:1": // Square
      ratio = 1 / 1
      break
    case "default":
      ratio = null
      break
    default:
      break
  }
  return ratio ? ratio * 100 + "%" : "0%"
}
