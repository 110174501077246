import React from "react"
import SbEditable from "storyblok-react"
import Components from "src/storyblok/Components.js"

const Hero = ({ blok }) => {
  const { media } = blok
  console.log("🚀 ~ file: Hero.js ~ line 15 ~ Hero ~ media", media)

  return (
    <SbEditable content={blok}>
      {media &&
        media.length > 0 &&
        media.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isCritical: true,
          })
        )}
    </SbEditable>
  )
}

export default Hero
