import React from "react"

const ProcessPath = ({ className, pathClassName, color, pathRef }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 449 1779"
    className={className}
  >
    <path
      ref={pathRef}
      className={pathClassName}
      fill="none"
      d="M208 1777.5s-207-72-207-282.22c0-456.2 447-416.74 447-206.13C448 1499.77.735 1534.12 1 1052.79c.265-481.344 447-438.66 447-228.937C448 1033.58 1 1077.6 1 591.255c0-474.749 447-452.196 447-236.459C448 570.532 1 582.38 1 157 1 79 23 1 23 1"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default ProcessPath
