import React from "react"
import Box from "src/components/UI/Layout/Box"

const Spacing = ({ blok }) => {
  const { height_mobile, height_desktop } = blok
  return (
    <Box height={[`${height_mobile}px`, null, null, `${height_desktop}px`]} />
  )
}

export default Spacing
