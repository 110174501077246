import React from "react"
import SbEditable from "storyblok-react"
import Components from "src/storyblok/Components.js"
import styled from "styled-components"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
import LinkComponent from "src/components/Components/LinkComponent"
import { getContentWidths } from "src/helpers/getContentWidths"

const Wrapper = styled(Flex)`
  a {
    text-decoration: none;
  }
`

const Label = styled(Column)`
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    ${({ customMobileWidth }) =>
      customMobileWidth &&
      `
  width:calc(${customMobileWidth * 100 + "%"});
`}
  }
`
const Title = styled(Column)`
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    ${({ customMobileWidth }) =>
      customMobileWidth &&
      `
  width:calc(${customMobileWidth * 100 + "%"});
`}
  }
`

const LinkWrapper = ({ children, hasLink, link, title }) =>
  hasLink ? (
    <LinkComponent blok={{ link: link, text: title }}>{children}</LinkComponent>
  ) : (
    children
  )

const Highlight = ({
  blok,
  width,
  customRatio,
  spacing = true,
  imageSize,
  carousel,
  isCritical = false,
}) => {
  const { media, label, title, text, link } = blok
  const highlightTitle = title ? title : label ? label : ""
  const hasLink = link && link.cached_url ? true : null
  const hasText = text ? true : null
  const hasContent = label || title || text ? true : null
  const { labelWidth, titleWidth, textWidth } = getContentWidths(width, hasText)

  if (!imageSize && width) {
    switch (width) {
      case "3/12":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "25vw" }
        break
      case "1/3":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "33vw" }
        break
      case "1/2":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
        break
      case "2/3":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "66vw" }
        break
      case "5/6":
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "90vw" }
        break
      case "1/1":
        imageSize = "100vw"
        break
      default:
        imageSize = { mobile: "100vw", tablet: "100vw", desktop: "50vw" }
        break
    }
  }

  return (
    <SbEditable content={blok}>
      <Wrapper
        width={1}
        flexDirection="column"
        mb={hasContent && spacing ? [6, null, null, 6] : 0}
        hasLink={hasLink ? 1 : 0}
      >
        <LinkWrapper hasLink={hasLink} link={link} title={highlightTitle}>
          {media && media.length > 0 && (
            <Box className="media image-wrapper" mb={[1, null, null, 2]}>
              {media.map(blok =>
                React.createElement(Components(blok.component), {
                  key: blok._uid,
                  blok: blok,
                  customRatio: customRatio,
                  spacing: false,
                  hover: hasLink,
                  imageSize: imageSize,
                  hasParentLink: hasLink,
                  isCritical: isCritical,
                })
              )}
            </Box>
          )}
          {hasContent && (
            <Row flexDirection="row">
              {label && (
                <Label
                  customMobileWidth={carousel ? 1 / 6 + 0.03061224 : null}
                  width={[1 / 6, null, null, labelWidth]}
                >
                  <Markdown blok={{ text: label }} />
                </Label>
              )}

              {title && (
                <Title
                  customMobileWidth={carousel ? 5 / 6 - 0.03061224 : null}
                  width={[5 / 6, null, null, titleWidth]}
                >
                  <Markdown blok={{ text: title }} />
                </Title>
              )}

              {text && textWidth && (
                <Column
                  width={[5 / 6, null, null, textWidth]}
                  mt={[3, null, null, 0]}
                  ml={["auto", null, null, 0]}
                >
                  <Markdown blok={{ text: text }} />
                </Column>
              )}
            </Row>
          )}
        </LinkWrapper>
      </Wrapper>
    </SbEditable>
  )
}

export default Highlight
