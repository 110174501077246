import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Video from "src/components/Components/Video"
import Hover from "src/components/UI/Elements/Hover"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Markdown from "src/storyblok/Components/Markdown"
import LinkComponent from "src/components/Components/LinkComponent"

const VideoWrapper = styled(Box)`
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }

  video {
    animation: fadeInBackground 0.1s ease forwards;
  }

  @keyframes fadeInBackground {
    0% {
      background: transparent;
    }
    100% {
      background: ${props => props.theme.colors.green};
    }
  }
`

const LinkWrapper = ({ children, hasLink, link, title }) =>
  hasLink ? (
    <LinkComponent blok={{ link: link, text: title }}>{children}</LinkComponent>
  ) : (
    children
  )

const VideoBlok = ({
  blok,
  hideCaption,
  customRatio,
  hover,
  spacing = true,
  hasParentLink,
}) => {
  const { text, link } = blok
  const hasLink = link && link.cached_url ? true : null
  return (
    <SbEditable content={blok}>
      <div className="video">
        <Flex
          flexDirection="column"
          width={1}
          mb={
            !hideCaption && text && !spacing
              ? [2, null, null, 6]
              : spacing
              ? [2, null, null, 3]
              : 0
          }
        >
          <LinkWrapper
            hasLink={!hasParentLink && hasLink ? true : false}
            link={link}
          >
            <VideoWrapper className="video">
              <Video customRatio={customRatio} blok={blok} />
              {hover && <Hover className="hover" />}
            </VideoWrapper>
          </LinkWrapper>
          {!hideCaption && text && (
            <Box mt={1}>
              <Markdown blok={blok} />
            </Box>
          )}
        </Flex>
      </div>
    </SbEditable>
  )
}

export default VideoBlok
