import React from "react"

const ProcessPathVertical = ({ className, pathClassName, color, pathRef }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 182"
  >
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      d="M12.4188 173.614L8.13477 179.809L1.63949 175.995"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      vectorEffect="non-scaling-stroke"
      fill="none"
      ref={pathRef}
      className={pathClassName}
      d="M7.77054 1C7.77054 1 5.38576 9.14772 5.38576 17.7542C5.38576 66.7481 53.8403 62.5108 53.8403 39.8913C53.8403 17.2718 5.35706 13.5823 5.38576 65.2763C5.41445 116.97 53.8403 112.386 53.8403 89.8628C53.8403 67.3393 5.38576 62.6112 5.38576 114.843C5.38576 165.829 53.8403 163.407 53.8403 140.238C53.8403 117.069 5.38576 115.796 5.38576 161.48C5.38576 169.857 7.77054 178.234 7.77054 178.234"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default ProcessPathVertical
