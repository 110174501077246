import React from "react"
import Components from "src/storyblok/Components.js"
import Box from "src/components/UI/Layout/Box"
import SeoWrapper from "src/components/SeoWrapper/SeoWrapper"
import { getSeoData } from "../../helpers/getSeoData"

const PageWork = ({ blok, isStartPage, name }) => {
  const { body, SEO } = blok

  const seoData = getSeoData(SEO)

  if (!seoData.title) {
    seoData.title = name
  }

  return (
    <Box pt={["12px", null, null, 3]}>
      <SeoWrapper {...seoData} />
      {body &&
        body.length > 0 &&
        body.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isStartPage: isStartPage,
          })
        )}
    </Box>
  )
}

export default PageWork
