import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Flex from "src/components/UI/Layout/Flex"
import Markdown from "src/storyblok/Components/Markdown"
import LinkBlok from "src/storyblok/Components/LinkBlok"
import StyledLink from "src/components/UI/Elements/StyledLink"
import Newsletter from "src/components/GlobalModules/Newsletter"
import { checkColorHex } from "src/helpers/checkColorHex"

const Wrapper = styled(Section)`
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    p {
      margin-bottom: 19px;
    }
  }
`

const Links = styled(Flex)`
  > * {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const Footer = ({ blok }) => {
  const { menu, contact, links, color_background, color_text } = blok
  return (
    <SbEditable content={blok}>
      <Wrapper
        zIndex={1}
        mt="auto"
        py={[4, null, null, 18]}
        bg={checkColorHex(color_background, "background")}
        color={checkColorHex(color_text, "text")}
      >
        <Container>
          <Row justifyContent="space-between">
            <Column
              width={[5 / 6, null, null, 5 / 12]}
              ml={[`${(1 / 6) * 100}%`, null, null, `${(1 / 12) * 100}%`]}
            >
              <Row>
                <Column
                  width={[4 / 5, null, null, 2 / 5]}
                  mb={[5, null, null, 0]}
                >
                  {menu && (
                    <Links flexDirection="column">
                      {menu.length > 0 &&
                        menu.map(blok => (
                          <StyledLink key={blok._uid}>
                            <LinkBlok blok={blok} />
                          </StyledLink>
                        ))}
                    </Links>
                  )}
                </Column>

                <Column
                  width={[4 / 5, null, null, 3 / 5]}
                  mb={[5, null, null, 0]}
                >
                  {contact && contact.length > 0 && (
                    <Markdown blok={contact[0]} />
                  )}
                </Column>
              </Row>
            </Column>
            <Column
              width={[5 / 6, null, null, 5 / 12]}
              ml={[`${(2 / 12) * 100}%`, null, null, 0]}
            >
              <Row>
                <Column
                  width={[5 / 5, null, null, 2 / 5]}
                  mb={[5, null, null, 0]}
                >
                  {links && (
                    <Flex
                      flexDirection={["row", null, null, "column"]}
                      flexWrap="wrap"
                    >
                      {links.length > 0 &&
                        links.map(blok => (
                          <StyledLink key={blok._uid} mr={[1, null, null, 0]}>
                            <LinkBlok blok={blok} />
                          </StyledLink>
                        ))}
                    </Flex>
                  )}
                </Column>
                <Column
                  flexDirection="column"
                  width={[5 / 5, null, null, 3 / 5]}
                >
                  <Newsletter blok={blok} />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </SbEditable>
  )
}

export default Footer
