import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
import H1 from "src/components/UI/Typography/H1"
import Text from "src/components/UI/Typography/Text"
import ProcessPath from "src/components/Components/ProcessPath/ProcessPath"
import { checkColorHex } from "src/helpers/checkColorHex"

const Line = styled(Column)`
  position: relative;
  ::after {
    background-color: black;
    content: " ";
    position: absolute;
    left: 0;
    top: 6px;
    width: 100%;
    height: 1.5px;
  }
`

const Process = styled(Row)`
  position: relative;
  .process-path {
    width: 100%;
    height: auto;
  }
`

const Steps = styled(Column)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    top: auto;
    position: relative;
    height: auto;
  }
`

const Step = styled(Column)`
  position: absolute;
  left: 0;
  width: 100%;
  top: ${({ number }) =>
    number === 1
      ? "5%"
      : number === 2
      ? "31%"
      : number === 3
      ? "57%"
      : number === 4
      ? "83%"
      : "auto"};
  bottom: ${({ number }) => (number === 5 ? "7%" : "auto")};

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    top: ${({ number }) =>
      number === 1
        ? "0%"
        : number === 2
        ? "19%"
        : number === 3
        ? "45%"
        : number === 4
        ? "71%"
        : "auto"};
    bottom: ${({ number }) => (number === 5 ? "0%" : "auto")};
  }

  .step-number {
    padding-right: 12px;
  }
`

const ApproachHeader = ({ blok }) => {
  const processRef = useRef()
  const pathRef = useRef()

  useEffect(() => {
    const pathLength = pathRef.current.getTotalLength()
    let pathOffset = pathLength
    let pathArray = pathLength + " " + pathLength

    pathRef.current.style.strokeDashoffset = pathOffset
    pathRef.current.style.strokeDasharray = pathArray
    const checkScroll = () => {
      const processTop =
        processRef && processRef.current
          ? processRef.current.getBoundingClientRect().top -
            window.innerHeight * 0.4
          : null
      const scrollTop = processTop * -1
      const processHeight = processRef.current.clientHeight

      if (scrollTop >= 0 && scrollTop <= processHeight) {
        // Get percentage from scrollTop / processHeight
        const percentage = (scrollTop / processHeight) * -1
        // Length to offset the dashes
        var drawLength = pathLength * percentage
        // Get strokeDashoffset from pathLength * percentage
        pathOffset = pathLength - drawLength
        // Get strokeDasharray from pathLength
        pathArray = pathLength + " " + pathLength
      }

      if (scrollTop > processHeight) {
        pathOffset = 0
        pathArray = "none"
      }

      pathRef.current.style.strokeDashoffset = pathOffset
      pathRef.current.style.strokeDasharray = pathArray
    }
    window.addEventListener("scroll", checkScroll)
    return () => {
      window.removeEventListener("scroll", checkScroll)
    }
  }, [])

  const { title, text, text_bottom, color_background, color_text } = blok

  let { step_1, step_2, step_3, step_4, step_5 } = blok

  step_1 = step_1 ? `<span class="step-number">1.</span>${step_1}` : null
  step_2 = step_2 ? `<span class="step-number">2.</span>${step_2}` : null
  step_3 = step_3 ? `<span class="step-number">3.</span>${step_3}` : null
  step_4 = step_4 ? `<span class="step-number">4.</span>${step_4}` : null
  step_5 = step_5 ? `<span class="step-number">5.</span>${step_5}` : null

  return (
    <SbEditable content={blok}>
      <Section mt={[3]} mb={[6, null, null, 6]} flexDirection="column">
        <Container>
          <Row justifyContent="space-between">
            <Column width={[6 / 6, null, null, 2 / 12]} mb={[8, null, null, 0]}>
              {title && <Text as="h1">{title}</Text>}
            </Column>
            <Column
              width={[5 / 6, null, null, 7 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, 0]}
              mr={[0, null, null, (1 / 12) * 100 + "%"]}
            >
              {" "}
              {text && (
                <H1 as="span">
                  <Markdown blok={{ text }} />
                </H1>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        bg={checkColorHex(color_background, "background")}
        color={checkColorHex(color_text, "text")}
        py={[10, null, null, 10]}
        mt={[4, null, null, 4]}
      >
        <Container>
          <Row justifyContent="flex-end">
            <Column
              width={[5 / 6, null, null, 7 / 12]}
              mr={[0, null, null, (1 / 12) * 100 + "%"]}
            >
              <Column width={[12 / 12, null, null, 4 / 7]}>
                <Row
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={[3, null, null, 3]}
                  mt={[23, null, null, 6]}
                >
                  <Column>Head</Column>
                  <Line flex="1" />
                  <Column>Hand</Column>
                </Row>
              </Column>
              <Process>
                <Column width={[12 / 12, null, null, 4 / 7]} ref={processRef}>
                  <ProcessPath
                    pathRef={pathRef}
                    className="process-path"
                    color="#000000"
                  />
                </Column>
                <Steps
                  ml={[(1 / 6) * 100 + "%", null, null, 0]}
                  width={[5 / 6, null, null, 3 / 7]}
                >
                  <Row flexDirection="column" flex="1">
                    {step_1 && (
                      <Step number={1} width={1}>
                        <Markdown escapeHtml={false} blok={{ text: step_1 }} />
                      </Step>
                    )}
                    {step_2 && (
                      <Step number={2} width={1}>
                        <Markdown escapeHtml={false} blok={{ text: step_2 }} />
                      </Step>
                    )}
                    {step_3 && (
                      <Step number={3} width={1}>
                        <Markdown escapeHtml={false} blok={{ text: step_3 }} />
                      </Step>
                    )}
                    {step_4 && (
                      <Step number={4} width={1}>
                        <Markdown escapeHtml={false} blok={{ text: step_4 }} />
                      </Step>
                    )}
                    {step_5 && (
                      <Step number={5} width={1}>
                        <Markdown escapeHtml={false} blok={{ text: step_5 }} />
                      </Step>
                    )}
                  </Row>
                </Steps>
              </Process>
            </Column>
          </Row>
          <Row justifyContent="flex-end">
            <Column
              width={[5 / 6, null, null, 7 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, 0]}
              mr={[0, null, null, (1 / 12) * 100 + "%"]}
            >
              {text_bottom && (
                <H1 as="span">
                  <Markdown blok={{ text: text_bottom }} />
                </H1>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default ApproachHeader
