import React from "react"
import SbEditable from "storyblok-react"
import Box from "src/components/UI/Layout/Box"
import styled from "styled-components"
import ReactMarkdown from "react-markdown/with-html"
import { LinkRenderer } from "src/helpers/markdownRenderers"

const StyledMarkdown = styled(Box)`
  column-count: 0;
  column-gap: 0;
  line-height: 1.2em;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    column-count: 2;
    column-gap: 40px;
  }
`

const List = ({ blok, dark, fullMobile }) => {
  return (
    <SbEditable content={blok}>
      <StyledMarkdown
        width={[6 / 6, null, null, 12 / 12]}
        dark={dark ? 1 : null}
      >
        <ReactMarkdown
          source={blok.content}
          renderers={{ link: LinkRenderer }}
        />
      </StyledMarkdown>
    </SbEditable>
  )
}

export default List
