import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Components from "src/storyblok/Components.js"
import Column from "src/components/UI/Layout/Grid/Column"

const GridColumn = styled(Column)`
  display: ${props =>
    props.visibility === "desktop" ? "none" : "flex"} !important;
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: ${props =>
      props.visibility === "mobile" ? "none" : "flex"} !important;
    ${props =>
      props.fill &&
      `
    flex: 1;

    > div,
    a {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .media {
        flex: 1;
    }

    .img,
    .img-wrapper,
    .video,
    .video > div {
        height: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
    }

   img {
        object-fit: cover;
        object-position: center;
    }
  `}
  }
`

const GridItem = ({ blok, spacing, mb }) => {
  const { width, fill, content, visibility } = blok
  let columnWidth = 12 / 12

  switch (width) {
    case "1/12":
      columnWidth = 1 / 12
      break
    case "2/12":
      columnWidth = 2 / 12
      break
    case "3/12":
      columnWidth = 3 / 12
      break
    case "1/3":
      columnWidth = 4 / 12
      break
    case "5/12":
      columnWidth = 5 / 12
      break
    case "1/2":
      columnWidth = 6 / 12
      break
    case "7/12":
      columnWidth = 7 / 12
      break
    case "2/3":
      columnWidth = 8 / 12
      break
    case "9/12":
      columnWidth = 9 / 12
      break
    case "5/6":
      columnWidth = 10 / 12
      break
    case "11/12":
      columnWidth = 11 / 12
      break
    case "1/1":
      columnWidth = 12 / 12
      break
    default:
      break
  }

  return (
    <SbEditable content={blok}>
      <GridColumn
        mx={!spacing ? [0, null, null, "-12px"] : 0}
        px={!spacing ? 0 : ["6px", null, null, "12px"]}
        position="relative"
        width={[12 / 12, null, null, columnWidth]}
        justifyContent="space-between"
        fill={fill ? 1 : null}
        visibility={visibility}
        mb={mb}
      >
        {content &&
          content.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              width: width,
              isFullWidth: columnWidth === 12 / 12 && !spacing ? true : false,
            })
          )}
      </GridColumn>
    </SbEditable>
  )
}

export default GridItem
