import React from "react"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"

const Insight = ({ blok, index }) => {
  const { label } = blok
  return (
    <Row flexDirection="row">
      <Column width={[1 / 6, null, null, 1 / 4]}>{`0${index + 1}`}</Column>
      <Column width={[5 / 6, null, null, 3 / 4]}>{label}</Column>
      <Column
        mt={[4, null, null, 6]}
        width={[5 / 6, null, null, 4 / 4]}
        ml={[(1 / 6) * 100 + "%", null, null, 0]}
      >
        <Markdown blok={blok} />
      </Column>
    </Row>
  )
}

export default Insight
