import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Chapter from "src/storyblok/ContentComponents/Chapter"
import { checkColorHex } from "src/helpers/checkColorHex"

const Wrapper = styled(Section)`
  > div {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Process = ({ blok, isFirst }) => {
  const { chapters, color_background, color_text } = blok
  return (
    <SbEditable content={blok}>
      <Wrapper
        bg={checkColorHex(color_background, "background")}
        color={checkColorHex(color_text, "text")}
        className="process"
        position="relative"
        flexDirection="column"
        py={[3, null, null, 6]}
        mb={[3, null, null, 6]}
      >
        {chapters &&
          chapters.length > 0 &&
          chapters.map((blok, index) => (
            <Chapter
              key={blok._uid}
              isChild={true}
              blok={blok}
              isFirst={isFirst && index === 0 ? true : null}
            />
          ))}
      </Wrapper>
    </SbEditable>
  )
}

export default Process
