import React from "react"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Member from "src/storyblok/Components/Member"

const Team = ({ blok }) => {
  const { members } = blok
  return (
    <SbEditable content={blok}>
      <Section>
        <Container>
          {members && members.length > 0 && (
            <Row flexDirection="row">
              {members.map(blok => (
                <Member key={blok._uid} blok={blok} />
              ))}
            </Row>
          )}
        </Container>
      </Section>
    </SbEditable>
  )
}

export default Team
