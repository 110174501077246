import React from "react"
import SbEditable from "storyblok-react"
import LinkComponent from "src/components/Components/LinkComponent"

const LinkBlok = ({ blok, text, className, onClick, style }) => {
  return (
    <SbEditable content={blok}>
      <LinkComponent
        blok={blok}
        text={text}
        className={className}
        onClick={onClick}
        style={style}
      ></LinkComponent>
    </SbEditable>
  )
}

export default LinkBlok
