import React from "react"
import Components from "src/storyblok/Components.js"
import SbEditable from "storyblok-react"
import Image from "src/components/Components/Image"
import Box from "src/components/UI/Layout/Box"
import styled from "styled-components"
import CaseHeader from "../Modules/CaseHeader"
import RelatedCases from "../Components/RelatedCases"
import SeoWrapper from "../../components/SeoWrapper/SeoWrapper"
import ImageWrapper from "src/components/UI/Elements/ImageWrapper"
import { getSeoData } from "../../helpers/getSeoData"
import isNode from "is-node"

const Wrapper = styled(Box)`
  .grid + .process {
    margin-top: 8px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      margin-top: 24px;
    }
  }

  .grid.grid--full-width + .process {
    margin-top: 8px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      margin-top: 0;
    }
  }
`

const PageCase = ({ blok, name }) => {
  const { body, hero_image, case_header, tags, SEO } = blok
  const seoData = getSeoData(SEO)
  const excerpt = case_header && case_header[0] ? case_header[0].excerpt : ""

  if (!seoData.title) {
    seoData.title = name
  }
  if (!seoData.ogImage) {
    seoData.ogImage = hero_image
  }
  if (!seoData.description) {
    seoData.description = excerpt
  }

  return (
    <Wrapper>
      <SeoWrapper {...seoData} />
      {hero_image && (
        <SbEditable content={blok}>
          <ImageWrapper>
            <Image
              src={hero_image}
              sizes={"100vw"}
              alt={name}
              ratio={3 / 2}
              isCritical
            />
          </ImageWrapper>
        </SbEditable>
      )}
      {case_header && case_header.length > 0 && (
        <CaseHeader name={name} blok={case_header[0]} />
      )}
      {body &&
        body.map((blok, index) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
            isCase: true,
            isFirst: index === 0 ? true : null,
          })
        )}
      {!isNode && <RelatedCases name={name} tags={tags} />}
    </Wrapper>
  )
}

export default PageCase
