import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import VimeoVideo from "src/components/Components/VimeoVideo"
import Hover from "src/components/UI/Elements/Hover"
import Flex from "src/components/UI/Layout/Flex"
import Box from "src/components/UI/Layout/Box"
import Markdown from "src/storyblok/Components/Markdown"

const VideoWrapper = styled(Box)`
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
`

const VimeoBlok = ({
  blok,
  hideCaption,
  customRatio,
  isFullWidth,
  hover,
  spacing = true,
}) => {
  const { text } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection="column"
        width={1}
        mt={isFullWidth ? [0, null, null, 3] : 0}
        mb={
          (!hideCaption && text && !spacing) || isFullWidth
            ? [2, null, null, 6]
            : spacing
            ? [2, null, null, 3]
            : 0
        }
      >
        <VideoWrapper>
          <VimeoVideo
            customRatio={customRatio}
            blok={blok}
            captionUnder={false}
          />
          {hover && <Hover className="hover" />}
        </VideoWrapper>
        {!hideCaption && text && (
          <Box mt={1}>
            <Markdown blok={blok} />
          </Box>
        )}
      </Flex>
    </SbEditable>
  )
}

export default VimeoBlok
