import React from "react"
import StyledLink from "src/components/UI/Elements/StyledLink"
import SbEditable from "storyblok-react"
import Column from "src/components/UI/Layout/Grid/Column"
import Text from "src/components/UI/Typography/Text"
import ImageBlok from "src/storyblok/Components/ImageBlok"

const LinkWrapper = ({ children, hasLink, mail }) =>
  hasLink ? (
    <StyledLink>
      <a
        className="o-link"
        href={`mailto:${mail}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </StyledLink>
  ) : (
    children
  )

const Member = ({ blok }) => {
  const { image, name, title, mail } = blok
  const hasLink = mail ? true : null
  return (
    <SbEditable content={blok}>
      <Column mb={3} width={[1 / 2, null, null, 1 / 4]}>
        <ImageBlok
          customRatio="4:5"
          imageSize={{ mobile: "100vw", tablet: "100vw", desktop: "33vw" }}
          hideCaption={true}
          blok={{ image: image, alt: name }}
        />
        <Text lineHeight={"1.2em"} color="black" as="p">
          <LinkWrapper hasLink={hasLink} mail={mail}>
            {name}
          </LinkWrapper>
        </Text>
        <Text lineHeight={"1.2em"} color="black" as="p">
          {title}
        </Text>
      </Column>
    </SbEditable>
  )
}

export default Member
