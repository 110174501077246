import React from "react"
import styled from "styled-components"
import Vimeo from "@u-wave/react-vimeo"
import Box from "src/components/UI/Layout/Box"
import Flex from "src/components/UI/Layout/Flex"
import { PlayIcon } from "src/components/UI/Icon/Icon"
import { formatImage } from "src/helpers/formatImage"
import { getVideoRatio } from "src/helpers/getVideoRatio"
import isNode from "is-node"

const VideoWrapper = styled(Box)`
  & > div {
    width: 100%;
  }

  position: relative;
  padding-bottom: 56.25%;
  padding-bottom: ${props => `${props.ratio}%`};
  padding-top: 0;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Overlay = styled(Flex)`
  ${props => props.theme.mixins.transition("opacity")}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 1;

  &.hidden {
    opacity: 0;
    ${props => props.theme.mixins.transition("opacity")}
    height: ${props => (props.hide_controls ? "auto" : 0)};
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    position: absolute;
    z-index: 1;
    top: 16px;
    left: 16px;

    width: 40px;
    height: 40px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 115px;
      height: 115px;
    }
  }

  p {
    z-index: 1;
    max-width: 680px;
    color: white;
    font-size: 14px;
    line-height: 1.3em;
    text-align: left;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      text-align: right;
      font-size: 20px;
      line-height: 1.5em;
    }
  }
`

class VimeoVideo extends React.Component {
  constructor(props) {
    super(props)

    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 200px 0px",
      })
    }

    this.state = {
      vimeoPlayer: null,
      videoStarted:
        this.props && this.props.blok && this.props.blok.autoplay
          ? true
          : false,
      videoPaused: true,
    }
  }

  observeHandler = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (this.props.blok.autoplay) {
          this.setState({
            videoPaused: false,
          })
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  vimeoOnReady = event => {
    this.setState({
      vimeoPlayer: event.element,
    })
  }

  handlePlayerPlayer = () => {
    if (this.state.videoPaused) {
      this.setState({
        videoStarted: true,
        videoPaused: false,
      })
    } else {
      this.setState({
        videoPaused: true,
      })
    }
  }

  render() {
    const { blok, customRatio } = this.props
    if (blok && blok.vimeo_url) {
      const { vimeo_url, ratio, thumbnail, autoplay, hide_controls } = blok

      return (
        <VideoWrapper
          width={1}
          ratio={
            customRatio
              ? getVideoRatio(customRatio)
              : ratio
              ? getVideoRatio(ratio)
              : getVideoRatio("16:9")
          }
          ref={x => {
            if (x) {
              this.observer.observe(x)
              this.videoRef = x
            }
          }}
        >
          <Vimeo
            controls={hide_controls || autoplay ? false : true}
            className={"vimeo"}
            video={vimeo_url}
            muted={autoplay ? true : false}
            loop={autoplay ? true : false}
            width={"100%"}
            showByline={false}
            showPortrait={false}
            showTitle={false}
            background={false}
            onReady={this.vimeoOnReady}
            paused={this.state.videoPaused}
          />
          {!autoplay && hide_controls ? (
            <Overlay
              px={["16px", null, null, "32px"]}
              py={["16px", null, null, "48px"]}
              alignItems="flex-end"
              justifyContent="flex-end"
              onClick={this.handlePlayerPlayer}
              className={this.state.videoStarted ? "hidden" : ""}
              hide_controls={hide_controls ? 1 : null}
            >
              <PlayIcon width={115} height={115} color="#ffffff" />
              {thumbnail && (
                <img src={formatImage(thumbnail)} alt={"Vimeo poster"} />
              )}
            </Overlay>
          ) : (
            <Overlay
              px={["16px", null, null, "32px"]}
              py={["16px", null, null, "48px"]}
            />
          )}
        </VideoWrapper>
      )
    } else {
      return null
    }
  }
}

export default VimeoVideo
