import React from "react"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Section from "src/components/UI/Layout/Section"
import { getCasesByTags } from "../../helpers/getCases"
import Case from "src/components/Components/Case"
import { Link } from "gatsby"
import H1 from "src/components/UI/Typography/H1"
import randomArray from "src/helpers/randomArray"

const RelatedCases = ({ name, tags }) => {
  const cases = tags && tags.length > 0 ? getCasesByTags(tags, name) : null
  const listedCases =
    cases.filter(project => !project.hide_listing === true) || []
  const randomCases = randomArray(listedCases)
    .slice(0, 3)
    .sort((a, b) => {
      return new Date(b.year) - new Date(a.year)
    })

  if (randomCases && randomCases.length > 0) {
    return (
      <Section py={[3, null, null, 5]}>
        <Container>
          <Row pb={[8, null, null, 5]}>
            <Column
              mb={[3, null, null, 0]}
              width={[5 / 6, null, null, 4 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, (1 / 12) * 100 + "%"]}
            >
              <h3>{"Further exploration"}</h3>
            </Column>
            <Column
              width={[5 / 6, null, null, 7 / 12]}
              ml={[`${(1 / 6) * 100}%`, null, null, 0]}
            >
              <H1 as="p">{`If you enjoyed this project for ${name} we think you might like some of these related projects.`}</H1>
            </Column>
          </Row>
          <Row>
            {randomCases.map(item => (
              <Column key={item.uuid} width={[12 / 12, null, null, 1 / 3]}>
                <Case key={item.uuid} item={item} ratio={"3:2"} width={1 / 3} />
              </Column>
            ))}
          </Row>
          <Row mb={4}>
            <Column>
              <Link to="/work">View all work</Link>
            </Column>
          </Row>
        </Container>
      </Section>
    )
  } else {
    return null
  }
}

export default RelatedCases
