import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Insight from "src/storyblok/Components/Insight"
import { checkColorHex } from "src/helpers/checkColorHex"
import ProcessPathVertical from "src/components/Components/ProcessPath/ProcessPathVertical"
import isNode from "is-node"
import LinkComponent from "src/components/Components/LinkComponent"

const Items = styled(Row)`
  > div {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const Path = styled(Column)`
  .process-path {
    width: 100%;
    height: auto;
    max-width: 30px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      max-width: 50px;
    }
  }

  .path {
    transition: all 1s cubic-bezier(0.42, 0, 0.35, 1) 0s;
  }
`

const StyledLink = styled(LinkComponent)`
  text-decoration: none;
  color: ${props => (props.color ? props.color : "black")} !important;
`

class Process extends React.Component {
  wrapperRef = null

  state = {
    visible: false,
  }

  constructor(props) {
    super(props)

    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 0px 0px",
      })
    }
  }

  observeHandler = entries => {
    for (const entry of entries) {
      const svg = entry.target.children.item(0)
      const path = svg.getElementsByClassName("path").item(0)
      const svgRatio =
        svg.getAttribute("viewBox").split(" ")[2] / svg.getBBox().width
      const pathLength = path ? path.getTotalLength() / svgRatio : null

      let pathOffset = pathLength ? pathLength : 0
      let pathArray = pathLength ? pathLength + " " + pathLength : "none"
      path.style.strokeDashoffset = pathOffset
      path.style.strokeDasharray = pathArray

      if (entry.isIntersecting && !this.state.visible) {
        path.style.strokeDashoffset = 0
        this.setState({ visible: true })
        this.observer.unobserve(entry.target)
      }
    }
  }

  render() {
    const { blok } = this.props
    const { items, color_background, color_text } = blok

    return (
      <SbEditable content={blok}>
        <Section
          bg={checkColorHex(color_background, "background")}
          color={checkColorHex(color_text, "text")}
          className="insights"
          pt={[6, null, null, 6]}
          pb={[6, null, null, 9]}
          mt={[3, null, null, 3]}
          mb={[3, null, null, 6]}
        >
          <Container>
            {items && items.length > 0 && (
              <Items>
                {items.map((blok, index) => {
                  return (
                    <Column
                      key={"insight-" + index}
                      width={[12 / 12, null, null, 3 / 12]}
                      mb={[8, null, null, 0]}
                    >
                      <Insight blok={blok} index={index} />
                    </Column>
                  )
                })}
                <Column
                  width={[5 / 6, null, null, 2 / 12]}
                  //   mt={[2, null, null, 9]}
                  ml={[(1 / 6) * 100 + "%", null, null, "auto"]}
                >
                  <Row alignItems="flex-start" flex="1">
                    <Path
                      width={1}
                      mb={[3, null, null, 3]}
                      ref={x => {
                        if (x) {
                          this.observer.observe(x)
                          this.wrapperRef = x
                        }
                      }}
                    >
                      <ProcessPathVertical
                        className="process-path"
                        pathClassName="path"
                        color={checkColorHex(color_text, "text")}
                      />
                    </Path>
                    <Column width={1} mt="auto">
                      <StyledLink
                        color={checkColorHex(color_text, "text")}
                        to="/approach"
                      >
                        Curious of how we work?
                        <br />
                        See our approach
                      </StyledLink>
                    </Column>
                  </Row>
                </Column>
              </Items>
            )}
          </Container>
        </Section>
      </SbEditable>
    )
  }
}

export default Process
