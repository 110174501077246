import styled from "styled-components"
import Text from "src/components/UI/Typography/Text"

const Heading = styled(Text)`
  ${props => props.theme.mixins.fontBedowTextHand}
  overflow-wrap: break-word;
  word-break: break-word;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    overflow-wrap: normal;
    word-break: normal;
  }
`

export default Heading
