import React from "react"
import styled from "styled-components"
import SbEditable from "storyblok-react"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Markdown from "src/storyblok/Components/Markdown"
import H1 from "src/components/UI/Typography/H1"
import { checkColorHex } from "src/helpers/checkColorHex"
import Components from "src/storyblok/Components"

const Step = styled(Column)``

const ApproachHeader = ({ blok }) => {
  const { image, steps, text, color_background, color_text } = blok

  return (
    <SbEditable content={blok}>
      <Section
        bg={checkColorHex(color_background, "background")}
        color={checkColorHex(color_text, "text")}
        pt={[6, null, null, 12]}
        pb={[7, null, null, 12]}
      >
        <Container>
          <Row flexDirection="row" justifyContent="center">
            <Column width={[1, null, null, 8 / 12]}>
              {image &&
                image.map(blok =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                  })
                )}
            </Column>
          </Row>
          {steps && steps.length > 0 && (
            <Row mt={[3, null, null, 7]}>
              <Column width={[1, null, null, 1 / 12]} />
              {steps.map((step, index) => {
                const { label, text } = step
                return (
                  <Step
                    key={`step-${index}`}
                    width={[5 / 6, null, null, 2 / 12]}
                    mb={[5, null, null, 0]}
                    ml={[(1 / 6) * 100 + "%", null, null, 0]}
                  >
                    <Row flexDirection="row">
                      <Column width={[1 / 5, null, null, "auto"]}>
                        {index + 1}.
                      </Column>
                      {label && (
                        <Column width={[4 / 5, null, null, "auto"]}>
                          {label}
                        </Column>
                      )}
                    </Row>
                    <Row mt={[3, null, null, 6]}>
                      {text && (
                        <Column width={[5 / 5, null, null, 3 / 3]}>
                          {text}
                        </Column>
                      )}
                    </Row>
                  </Step>
                )
              })}
            </Row>
          )}
        </Container>
        <Container>
          <Row justifyContent="flex-end" mt={[9, null, null, 10]}>
            <Column
              width={[5 / 6, null, null, 7 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, 0]}
              mr={[0, null, null, (1 / 12) * 100 + "%"]}
            >
              {text && (
                <H1 as="span">
                  <Markdown blok={{ text: text }} />
                </H1>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </SbEditable>
  )
}

export default ApproachHeader
