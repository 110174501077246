const randomArray = (currentArray = []) => {
  const mixedArray = [...currentArray]
  for (let i = mixedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = mixedArray[i]
    mixedArray[i] = mixedArray[j]
    mixedArray[j] = temp
  }
  return mixedArray
}

export default randomArray
