import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Components from "src/storyblok/Components.js"
import Section from "src/components/UI/Layout/Section"
import Container from "src/components/UI/Layout/Grid/Container"
import Row from "src/components/UI/Layout/Grid/Row"
import Column from "src/components/UI/Layout/Grid/Column"
import Box from "src/components/UI/Layout/Box"
import H1 from "src/components/UI/Typography/H1"
import Text from "src/components/UI/Typography/Text"
import LinkBlok from "src/storyblok/Components/LinkBlok"
import { Above, Below } from "src/components/UI/Breakpoints"
import StyledLink from "src/components/UI/Elements/StyledLink"

const StyledContainer = styled(Container)`
  a,
  a:visited {
    color: black;
  }
`

const StyledColumn = styled(Column)`
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    position: relative;
    top: -3px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a,
  a:visited {
    color: black;
  }
`

const ParentWrapper = ({ isChild, children }) =>
  !isChild ? <Section mt={[6, null, null, 6]}>{children}</Section> : children

const Chapter = ({ blok, isChild, isFirst }) => {
  const { title, label, spacing, content, cta } = blok
  return (
    <SbEditable content={blok}>
      <ParentWrapper isChild={isChild}>
        <StyledContainer mb={[6, null, null, 6]} width={12 / 12}>
          <Row>
            <Column
              width={[5 / 6, null, null, 2 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, 0]}
            >
              {title && (
                <Box
                  mt={[0, null, null, "2px"]}
                  mb={spacing ? [7, null, null, 0] : [2, null, null, 0]}
                >
                  <Text as={isFirst ? "h1" : ""}>{title}</Text>
                </Box>
              )}
            </Column>
            <Column
              width={[5 / 6, null, null, 2 / 12]}
              ml={[(1 / 6) * 100 + "%", null, null, 0]}
              justifyContent="space-between"
            >
              {label && (
                <Box
                  mt={[0, null, null, "2px"]}
                  mb={
                    !title && spacing ? [7, null, null, 0] : [1, null, null, 0]
                  }
                >
                  {label}
                </Box>
              )}
              {cta && cta.length > 0 && (
                <StyledLink mt="auto" mb={[0, null, null, 1]}>
                  <Above breakpoint="desktop">
                    <LinkBlok blok={cta[0]} />
                  </Above>
                </StyledLink>
              )}
            </Column>
            <Column width={[12 / 12, null, null, 8 / 12]}>
              {content && (
                <Row>
                  {content.map(blok => {
                    return blok.component === "markdown" ? (
                      <StyledColumn
                        key={blok._uid}
                        width={[5 / 6, null, null, 7 / 8]}
                        ml={[(1 / 6) * 100 + "%", null, null, 0]}
                        mb={[5, null, null, 5]}
                      >
                        <H1 as="span">
                          {React.createElement(Components(blok.component), {
                            key: blok._uid,
                            blok: blok,
                          })}
                        </H1>
                      </StyledColumn>
                    ) : (
                      <StyledColumn
                        key={blok._uid}
                        width={[12 / 12, null, null, 7 / 8]}
                        mb={[5, null, null, 5]}
                      >
                        {React.createElement(Components(blok.component), {
                          key: blok._uid,
                          blok: blok,
                        })}
                      </StyledColumn>
                    )
                  })}
                </Row>
              )}
            </Column>
            {cta && cta.length > 0 && (
              <Column
                width={[5 / 6, null, null, 12 / 12]}
                ml={[(1 / 6) * 100 + "%", null, null, 0]}
              >
                <Box mt={[5, null, null, 0]}>
                  <Below breakpoint="desktop">
                    <StyledLink>
                      <LinkBlok blok={cta[0]} />
                    </StyledLink>
                  </Below>
                </Box>
              </Column>
            )}
          </Row>
        </StyledContainer>
      </ParentWrapper>
    </SbEditable>
  )
}

export default Chapter
