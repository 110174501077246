import React from "react"
import styled from "styled-components"
import Box from "src/components/UI/Layout/Box"
import Flex from "src/components/UI/Layout/Flex"
import { getVideoRatio } from "src/helpers/getVideoRatio"
import isNode from "is-node"

import {
  SoundOffIcon,
  SoundOnIcon,
  PlayIcon,
} from "src/components/UI/Icon/Icon"

const VideoWrapper = styled(Box)`
  position: relative;
  padding-bottom: 52.56%;
  padding-bottom: ${({ ratio }) => (ratio && ratio > 0 ? `${ratio}%` : 0)};

  .videoControls {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    height: calc(100% + 1px);
    object-fit: cover;
    overflow: hidden;

    &.playControls {
      cursor: pointer;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      cursor: pointer;
      letter-spacing: inherit;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-align: center;
      text-transform: inherit;
      transition: opacity 0.3s ease;
      opacity: ${({ playing }) => (playing ? 1 : 0)};
      pointer-events: ${({ playing }) => (playing ? "default" : "none")};
    }

    .playIcon {
      transition: opacity 0.3s ease;

      opacity: ${({ playing }) => (playing ? 0 : 1)};

      svg {
        filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.15));
      }
    }
  }

  .soundIcon {
    svg {
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.15));
    }
  }

  video {
    width: 100%;
    ${({ ratio }) =>
      ratio > 0 &&
      `
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 100%;
      min-width: 100%;
      height: calc(100% + 1px);
      object-fit: cover;
      overflow: hidden;
    `}
  }
`

const VideoPlaceholder = styled(Box)`
  padding-bottom: 52.56%;
  padding-bottom: ${props => `${props.ratio}%`};
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

class Video extends React.Component {
  videoRef = null

  constructor(props) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: "0px 0px 200px 0px",
      })
    }

    this.state = {
      muted: true,
      playing: true,
    }
  }

  muteVideo() {
    if (this.videoRef) {
      this.setState({ muted: true })
      this.videoRef.muted = true
    }
  }

  unMuteVideo() {
    if (this.videoRef) {
      this.setState({ muted: false })
      this.videoRef.muted = false
    }
  }
  pauseVideo() {
    if (this.videoRef) {
      setTimeout(() => {
        this.videoRef.pause()
        this.setState({ playing: false, muted: true })
        this.videoRef.muted = true
      }, 300)
    }
  }

  playVideo(mute = true) {
    if (this.videoRef) {
      setTimeout(() => {
        this.videoRef.play()
        this.videoRef.muted = mute
        this.setState({ playing: true, muted: mute })
      }, 300)
    }
  }

  componentDidMount() {
    const {
      blok: { play_controls },
    } = this.props

    if (this.videoRef && play_controls) {
      this.pauseVideo()
    }
  }

  observeHandler = entries => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (this.videoRef) {
          if (!this.props.blok.play_controls && !this.state.playing) {
            console.log("Play video")
            this.playVideo()
          } else {
            // console.log("Load video")
            // this.videoRef.load()
          }

          // Debugging
          //   var videoPromise = this.videoRef.play()
          //   if (videoPromise !== undefined) {
          //     videoPromise
          //       .then(_ => {
          //         console.log("Autoplay started!")
          //       })
          //       .catch(error => {
          //         console.log("Autoplay was prevented.")
          //         console.log("error", error)
          //       })
          //   }
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  render() {
    const { muted, playing } = this.state

    const { blok, customRatio } = this.props
    const { video, ratio, sound_controls, play_controls, iteration } = blok
    const ext = video ? video.substr(video.lastIndexOf(".") + 1) : null

    const loop = iteration === "once" ? false : true

    if (video && ext && ext === "mp4") {
      return (
        <VideoWrapper
          playing={playing}
          ratio={
            customRatio
              ? getVideoRatio(customRatio)
              : ratio
              ? getVideoRatio(ratio)
              : getVideoRatio("16:9")
          }
        >
          {(sound_controls || play_controls) && (
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              className={[
                "videoControls",
                play_controls ? "playControls" : "",
              ].join(" ")}
            >
              <Box flex="1" />
              <Flex
                flex="2"
                justifyContent="center"
                alignItems="center"
                onClick={() =>
                  !playing ? this.playVideo(false) : this.pauseVideo()
                }
              >
                {play_controls && (
                  <div className="playIcon">
                    <PlayIcon />
                  </div>
                )}
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                paddingLeft={[2, null, null, 6]}
                paddingBottom={[2, null, null, 5]}
                flex="1"
              >
                {sound_controls && (
                  <button
                    className="soundIcon"
                    onClick={() =>
                      !muted ? this.muteVideo() : this.unMuteVideo()
                    }
                  >
                    {muted ? <SoundOffIcon /> : <SoundOnIcon />}
                  </button>
                )}
              </Flex>
            </Flex>
          )}
          <video
            muted
            loop={loop}
            playsInline
            autoPlay={!play_controls ? true : false}
            ref={x => {
              if (x) {
                this.observer.observe(x)
                this.videoRef = x
              }
            }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoWrapper>
      )
    } else {
      return (
        <VideoPlaceholder>
          <Flex justifyContent="center" alignItems="center">
            Upload mp4 video format
          </Flex>
        </VideoPlaceholder>
      )
    }
  }
}

export default Video
