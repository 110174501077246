import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import Video from "src/components/Components/Video"
import Box from "src/components/UI/Layout/Box"
import LinkComponent from "src/components/Components/LinkComponent"

const VideoWrapper = styled(Box)`
  position: relative;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }

  video {
    animation: fadeInBackground 0.1s ease forwards;
  }

  @keyframes fadeInBackground {
    0% {
      background: transparent;
    }
    100% {
      background: ${props => props.theme.colors.green};
    }
  }

  > div {
    min-height: 52vw;
    height: 80vh;
    max-height: 125vw;
    padding-bottom: 0;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      padding-bottom: 0;
      min-height: 50vw;
      height: 90vh;
      max-height: 56vw;
    }
  }
`

const LinkWrapper = ({ children, hasLink, link, title }) =>
  hasLink ? (
    <LinkComponent blok={{ link: link, text: title }}>{children}</LinkComponent>
  ) : (
    children
  )

const VideoFull = ({ blok }) => {
  const { video, link } = blok
  const hasLink = link && link.cached_url ? true : null

  const videoBlok = {
    video: video,
    ratio: "16/9",
    sound_controls: false,
    play_controls: false,
    iteration: "loop",
  }

  return (
    <SbEditable content={blok}>
      {video && (
        <div className="video">
          <LinkWrapper hasLink={hasLink} link={link}>
            <VideoWrapper>
              <Video blok={videoBlok} />
            </VideoWrapper>
          </LinkWrapper>
        </div>
      )}
    </SbEditable>
  )
}

export default VideoFull
